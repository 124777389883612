import React from 'react'

function PrimaryButton1(props) {
  return (
    <button className={`${props.className} bg-[#6507FF] px-[38px] py-[12px] font-[600] text-[20px] rounded-[3px] text-white transition ease-out hover:bg-[#5106cc]`}>
        {props.children}
        {props.underline !== false && (
          <span className='h-[1px] w-[100%] -translate-y-[4px] bg-white block'></span>
        )}
    </button>
  )
}

export default PrimaryButton1