import React from 'react';
import img from '../../utils/partner.png';
import Footer from '../../Home Sections/Footer';

const Partner = () => {
  return (
    <>
      <div className="">
        <img src={img} className="fixed z-[-1] right-0 bottom-[55px]" />
        <div className="container m-auto px-4 py-[50px] lg:py-[75px] mb-12">
          <div className="">
            <h1 className="text-[22px] sm:text-[32px] lg:text-[48px] font-[400] text-[#E5E5E5] lg:leading-[58.09px] mb-[32px] sm:mb-[48px] lg:mb-[67px]">
              <span className="text-[#07FFB5] text-[40px] sm:text-[54px] lg:text-[70px] font-[600] leading-[50px] lg:leading-[84.72px]">Make money</span><br />
              With the ultimate affiliate side hustle
            </h1>

            <div className="flex flex-wrap gap-[62px] mb-[52px] sm:mb-[68px] lg:mb-[87px]">
              <div className="bg-[#1C1C1C] w-full lg:max-w-[449px] h-[240px] rounded-[5px] px-[1rem] py-[2rem] sm:px-[49px] sm:py-[33px]">
                <h3 className="flex gap-[10px] mb-[18px] leading-[36px]">
                  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.0614 15.6816L31.0497 4.99707C30.9984 4.44336 30.5575 4.00586 30.0038 3.95117L19.3192 2.93945H19.3055C19.1961 2.93945 19.1107 2.97363 19.0458 3.03857L3.03941 19.0449C3.00772 19.0765 2.98259 19.1141 2.96543 19.1555C2.94828 19.1968 2.93945 19.2411 2.93945 19.2859C2.93945 19.3307 2.94828 19.375 2.96543 19.4163C2.98259 19.4577 3.00772 19.4952 3.03941 19.5269L15.474 31.9614C15.5389 32.0264 15.6244 32.0605 15.7167 32.0605C15.8089 32.0605 15.8944 32.0264 15.9593 31.9614L31.9657 15.9551C32.034 15.8833 32.0682 15.7842 32.0614 15.6816ZM23.8924 13.2275C22.6859 13.2275 21.7049 12.2466 21.7049 11.04C21.7049 9.8335 22.6859 8.85254 23.8924 8.85254C25.099 8.85254 26.0799 9.8335 26.0799 11.04C26.0799 12.2466 25.099 13.2275 23.8924 13.2275Z" fill="#1580FF"/>
                  </svg>
                  <span className="text-white text-[24px] font-[600]">Commission</span>
                </h3>
                <p className="text-white text-[20px] font-[600]">
                  Earn 50% commission for the first deposit on each referral, the highest industry percentage
                </p>
              </div>

              <div className="bg-[#1C1C1C] w-full lg:max-w-[449px] h-[240px] rounded-[5px] px-[1rem] py-[2rem] sm:px-[49px] sm:py-[33px]">
                <h3 className="flex gap-[10px] mb-[18px] leading-[36px]">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.7917 16.3333V3.20829H7.66667V0.291626H23.7083V16.3333H20.7917ZM13.5 23.625V10.5H0.375V7.58329H16.4167V23.625H13.5Z" fill="#DD7CFF"/>
                  </svg>
                  <span className="text-white text-[24px] font-[600]">No Limits</span>
                </h3>
                <p className="text-white text-[20px] font-[600]">
                  No earnings limit. Send as many customers as you want!
                </p>
              </div>
            </div>

            <div className="flex gap-[16px] min-[393px]:gap-[24px] min-[404px]:gap-[35px]">
              <button className="bg-[#6507FF] rounded-[11px] px-[20px] py-[12px] min-[393px]:px-[32px] min-[393px]:py-[12px] min-[438px]:px-[50px] min-[438px]:py-[14px] font-[600] text-[18px] min-[393px]:text-[20px] text-white leading-[30px] transition ease-out hover:bg-[#6607ffc9]">Join Now</button>
              <button className="bg-transparent text-white border-[1px] border-[#878787] px-[20px] py-[12px] min-[393px]:px-[32px] min-[393px]:py-[12px] min-[438px]:px-[31px] min-[438px]:py-[14px] rounded-[11px] font-[600] text-[18px] min-[393px]:text-[20px] leading-[30px] transition ease-out hover:bg-[#1C1C1C]">Affilate Login</button>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
      </>
  )
}

export default Partner