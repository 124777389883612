import React from 'react'
import PrimaryButton1 from './Components/PrimaryButton1';
import img from '../utils/ai-canvas.png';
import { Fade } from 'react-reveal';

function Section5() {
  return (
    <div className="container m-auto px-4 py-[50px] lg:py-[195px] mt-[160px]">
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-[48px]">
            <Fade bottom>
                <div className="md:col-span-2">
                    <span className="text-[#8000FF] text-[14px] font-[500] md:px-[38px] py-[6px]">AI Canvas</span>
                    <h1 className="text-[#F1F1F1] text-2xl md:text-[48px] font-[600] md:leading-[72px] mb-[32px]">Expand pictures beyond their borders.</h1>
                    <p className="text-[#C3C3C3] leading-[24px] mb-[48px]">
                        Our AI canvas is where creativity knows no limits. Harness the power of cutting-edge artificial intelligence to transform your ideas into stunning visuals. Whether you're an artist, designer, or simply seeking inspiration, our canvas provides a limitless space for image generation, pushing the boundaries of what's possible in the world of digital art and design
                    </p>
                    <PrimaryButton1>Try it out</PrimaryButton1>
                </div>
                <div className="row-start-1 xl:row-start-auto md:col-span-3 flex flex-col lg:flex-row justify-center items-center relative xl:justify-end">
                    <img src={img} width={677} height={386} className="mb-[14px] md:mb-[16px] lg:mb-0" />
                    <div className="lg:absolute left-0 bottom-0 w-full">
                        <div className="flex gap-[6px] items-center mb-[8px] md:mb-[14px]">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_746_67)">
                                <path d="M11 2.575V3.925C11 5.05 10.55 5.5 9.425 5.5H8.075C6.95 5.5 6.5 5.05 6.5 3.925V2.575C6.5 1.45 6.95 1 8.075 1H9.425C10.55 1 11 1.45 11 2.575ZM5.5 8.075V9.425C5.5 10.55 5.05 11 3.925 11H2.575C1.45 11 1 10.55 1 9.425V8.075C1 6.95 1.45 6.5 2.575 6.5H3.925C5.05 6.5 5.5 6.95 5.5 8.075Z" stroke="#00FFA3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.38 5.19V6.5C8.38 7.845 7.845 8.38 6.5 8.38H5.19V7.765C5.19 6.64 4.74 6.19 3.615 6.19H3V4.88C3 3.535 3.535 3 4.88 3H6.19V3.615C6.19 4.74 6.64 5.19 7.765 5.19H8.38Z" stroke="#00FFA3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_746_67">
                                <rect width="12" height="12" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>

                            <span className="text-xs md:text-[16px] font-[600] text-white">Prompts</span>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <input type="text" className="text-[#B0B0B0] bg-[#323232] px-[16px] py-[16px] md:px-[20px] md:py-[26px] rounded-es-none rounded-ee-none md:rounded-es-[6px] md:rounded-se-none rounded-[6px] w-full lg:max-w-[502px] md:text-[16px] font-[500] md:leading-[24px] focus-visible:outline-none" placeholder="Prompts" />
                            <PrimaryButton1 className="rounded-[6px] rounded-ss-none rounded-se-none md:rounded-se-[6px] md:rounded-ee-[6px] md:rounded-s-none" underline={false}>Generate</PrimaryButton1>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    </div>
  )
}

export default Section5;