import React from 'react'
import img from '../utils/help-avatar.png'
import Cropsvg from './Components/Cropsvg'
import { Fade } from 'react-reveal'

const GetStarted = () => {
  return (
    <div>
      <div className="container-fluid bg-[#680CFF] relative flex justify-center h-[556px] mt-[15  rem] md:mt-[30rem]">
        <Fade bottom>
          <div className="container bg-[#E1E1E1] m-20 ps-10 rounded-3xl  absolute top-[-302px] md:flex">
            <div className='md:p-20 p-5'>
              <h2 className='text-[#2A2A2A] text-4xl md:text-6xl font-semibold'>Our <span className="bg-text-gradient text-4xl md:text-6xl">AI</span> tools will help to shape your imagination</h2>
              <div className="mt-10 mb-20">
                <p className='paragraph'>Take control of your image’s look and feel. Remove a distraction or add something in. Edit the whole image or just a part of it.</p>
              </div>
              <button className='get-started-btn'>Get Started</button>
            </div>
            <div className='h-[250px] w-[250px] md:h-[341px] md:w-[341px] m-5 md:m-40 relative'>
              <span className='absolute left-[-212px] top-[50px] hidden md:block'>
                <Cropsvg/>
              </span>
              <img src={img} alt="" className='rounded-2xl' />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default GetStarted