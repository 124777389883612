import React from 'react'
import img from '../utils/generate1logo.png'

const Footer = () => {
  return (
    <div>
      <div className="container-fluid bg-[#232426] flex justify-center rounded-ss-[50px] md:rounded-ss-[100px] rounded-se-[50px] md:rounded-se-[100px]">
        <div className="container pt-20 ps-4  mb-20">
          <div class="grid grid-cols-1 md:grid-cols-5 gap-4">
            <div className='flex gap-5'>
              <img src={img} alt="" className='w-[51px] h-[59px]' />
              <span className='text-white font-semibold text-2xl pt-3'>Generate.One</span>
            </div>
            <div class="pt-[12px] ">
              <p className='text-white font-semibold text-xl'>Product</p>
              <ul>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>AI Art Generator</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Image Editor</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>AI Canvas</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>ControlNet</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>DreamBooth</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Pricing</a></li>
              </ul>
              <p className='text-white font-semibold text-xl mt-10'>Use cases</p>
              <ul>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Architecture</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Anime Generator</a></li>
              </ul>
            </div>
            <div class="pt-[12px] ">
              <p className='text-white font-semibold text-xl'>AI Models</p>
              <ul>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Stable Diffuison XL</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Relastic Vision</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Analog Diffuison</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Anime Diffuison</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Disney Diffuison</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Ghbibli Diffuison</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Inkpunk Diffuison</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Van Gogh Diffuison</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Dreamshaper</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Never Ending Dream</a></li>
              </ul>
            </div>
            <div class="pt-[12px] ">
              <p className='text-white font-semibold text-xl'>Resources</p>
              <ul>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Privacy Policy</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Terms of Service</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Restriction</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Affiliate Program</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Suggest a feature</a></li>
              </ul>
              <p className='text-white font-semibold text-xl mt-10'>About</p>
              <ul>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Guides</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>FAQ</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Email</a></li>
              </ul>
            </div>
            <div class="pt-[12px] ">
              <p className='text-white font-semibold text-xl'>API</p>
              <ul>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>About</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>API Pricing</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Docs</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Dashboard</a></li>
                <li className=' mt-1 pt-1'><a href="" className='text-gray-400'>Status</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer