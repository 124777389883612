import React from 'react'
import img1 from '../utils/sec4img1.png'
import img2 from '../utils/sec4img2.png'
import img3 from '../utils/sec4img3.png'
import { Fade } from 'react-reveal'

const Section4 = () => {
  return (
    <div className='text-center'>
      <div className='grid-cols-1 flex justify-center mt-20'>
        <div className='text-center'>
          <h2 className='text-white text-5xl'>Generative Fill</h2>
          <p className='text-white pt-6'>Create, add to, remove or replace images right in Generaet.One with simple text prompts</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center mt-10">
        <div className="container mt-5">
          <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-5">
            <Fade bottom>
              <div className='relative flex flex-col justify-center w-full md:w-[30rem] items-center'>
                <img src={img1} alt="" />
                <div className='bg-[#21212194] bg-opacity-50 rounded-2xl p-6  mt-5 md:absolute md:top-[230px] md:h-48'>
                  <h2 className='text-white text-2xl text-center md:text-left font-semibold leading-9'>Dream it, type it, see it.</h2>
                  <p className='text-[#9B9B9B] text-center md:text-left font-medium pt-5'>Go from text prompt to astounding art in a snap with Generative Fill, Add and remove content from images using everyday language. Plus, expand your canvas and increase aspect ratios with Generative Expand</p>
                </div>
              </div>
              <div className='relative flex flex-col justify-center w-full md:w-[30rem] items-center'>
                <img src={img2} alt="" />
                <div className='bg-[#21212194] bg-opacity-50 rounded-2xl p-6  mt-5 md:absolute md:top-[230px] md:h-48'>
                  <h2 className='text-white text-2xl text-center md:text-left font-semibold leading-9'>More ideas, less time.</h2>
                  <p className='text-[#9B9B9B] text-center md:text-left font-medium pt-5'>Quickly explore and experiment with ideas. Use Generative Fill to create dozens of high-quality concepts quickly with results only a few keystrokes away.</p>
                </div>
              </div>
              <div className='relative flex flex-col justify-center w-full md:w-[30rem] items-center'>
                <img src={img3} alt="" />
                <div className='bg-[#21212194] bg-opacity-50 rounded-2xl p-6  mt-5 md:absolute md:top-[230px] md:h-48'>
                  <h2 className='text-white text-2xl text-center md:text-left font-semibold leading-9'>Unbound your Creativity.</h2>
                  <p className='text-[#9B9B9B] text-center md:text-left font-medium pt-5'>You can use Generative Fill and Generative Expand to make powerful edits and additions to images, then perfect them with precise editing tools.</p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section4