import React from 'react';
import Poppins from 'google-fonts'; // Make sure you're importing Google Fonts correctly
import VideoPlayer from './Components/VideoPlayer';
import video1 from '../utils/video1.mp4';
import { Fade } from 'react-reveal';

// notes
/* Not responsive for small devices and other responsive ness issues */

const Section3 = () => {
  const semiBoldPoppins = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '600',
  };

  const regularPoppins = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
  };

  const mediumPoppins = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
  };

  return (
    <div className="bg-[#101010]">
      <div className="container m-auto px-4">
        <div className='bg-[#101010] relative flex flex-col lg:flex-row  items-start lg:items-center lg:gap-[5rem] gap-[1rem] text-white w-[100%] lg:py-[5rem] py-[4rem] md:py-[4rem] '>
          <Fade bottom>
              <div className='flex items-center lg:w-[50%]'>
                <div>
                  <h1 style={{ ...semiBoldPoppins }} className='lg:text-[3rem] text-[2rem]'>
                    Deforum Video
                  </h1>
                  <p style={{ ...regularPoppins }} className='lg:text-[20px] md:text-[0.8rem]'>
                    Generate beautiful morphing videos from input prompts
                    <br className='hidden xl:inline' /> and camera settings, Use ControlNet & Video inputs for more
                    refined
                    <br className='hidden min-[1536px]:inline' /> controls
                  </p>
                </div>
              </div>
              <div className='flex justify-center items-center lg:text-[1rem] md:text-[0.7rem]'>
                <div className='flex gap-[1rem] lg:gap-[25px] md:gap-[1.5rem] flex-wrap lg:flex-nowrap' style={{...mediumPoppins}}>
                  <button className='rounded-[36px] px-[1rem] py-[.8rem] sm:px-[2rem] sm:py-[1rem] bg-[#6507FF] w-[230px] cursor-pointer'>
                    Try Deforum Video
                  </button>
                  <button className='border-solid border-[1px] border-[#373737] text-[#817A7A] rounded-[36px] px-[1rem] py-[.8rem] sm:px-[2rem] sm:py-[1rem] w-[230px] cursor-pointer'>
                    Sign Up
                  </button>
                </div>
              </div>
          </Fade>
        </div>
      </div>
      <div className='h-[50vh] w-[100vw] overflow-hidden rounded-[10px]'>
        <video
          src={video1}
          autoPlay
          muted
          loop
          playsInline
          className='w-full h-full object-cover' 
          
        />
      </div>
    </div>
  );
};

export default Section3;
