import React, { useState } from 'react';
import logo from '../../utils/generate1logo.png';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [pathname, _setPathname] = useState(
    (new URL(window.location.href)).pathname
  );

  const setPathname = () => {
    setTimeout(() => {
      _setPathname((new URL(window.location.href)).pathname);
    }, 100);
  }

  const navBtnStyle = {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '600',
  };

  const activeBtn = {
    color: '#ffffff',
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="bg-[#151515] flex justify-between items-center py-[20px] lg:px-[5rem] md:px-[2rem] px-[1rem] relative sticky z-[9999] top-0">
      <div className="">
        <img src={logo} alt="Generate.one Logo" className="w-[2.8125rem] sm:w-[2.45rem] shrink-0" />
      </div>
      <div className="absolute flex items-center left-1/2 transform -translate-x-1/2 hidden lg:block">
        <ul className="flex text-[#AEAEAE] text-[0.8em] gap-[3.25rem] shrink-0" style={navBtnStyle}>
          <li style={pathname === '/'? activeBtn: {}}>
            <Link to="/" onClick={() => setPathname()}>Welcome</Link>
          </li>
          <li>
            <Link to="/" onClick={() => setPathname()}>Pricing</Link>
          </li>
          <li>
            <Link to="/" onClick={() => setPathname()}>API</Link>
          </li>
          <li>
            <Link to="/" onClick={() => setPathname()}>Documentation</Link>
          </li>
          <li style={pathname === '/partner'? activeBtn: {}}>
            <Link to="/partner" onClick={() => setPathname()}>Partner</Link>
          </li>
        </ul>
      </div>
      <span className="hidden lg:block">
        <div style={navBtnStyle} className="flex gap-[1rem] ">
          <button className="border-none text-[#C7C7C7] text-[0.8rem] shrink-0">Sign Up</button>
          <button className="bg-[#6507FF] text-white text-[0.8em] px-[2.7em] py-[1em]  rounded-full shrink-0">Launch</button>
        </div>
      </span>
      <div className="block lg:hidden" onClick={toggleMobileMenu}>
        <div className="bg-[#2A2A2A] p-[1em] rounded-sm">
          <DehazeIcon className="text-[#636363]" />
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-[1000] bg-[rgba(0,0,0,0.8)]">
          <div className="absolute inset-y-0 left-0 max-w-[80%] w-[300px] bg-[#2A2A2A] shadow-lg">
            <div className="">
              <img src={logo} alt="Generate.one Logo" className="w-[2.8125rem] sm:w-[2.45rem] shrink-0 ml-[2rem] mt-[0.75rem]" />
            </div>
            <ul className="flex flex-col text-[#AEAEAE] text-[0.8em] gap-[1rem] p-[2rem]">
              <li style={pathname === '/'? activeBtn: {}}>
                <Link to="/" onClick={() => setPathname()}>Welcome</Link>
              </li>
              <li>
                <Link to="/" onClick={() => setPathname()}>Pricing</Link>
              </li>
              <li>
                <Link to="/" onClick={() => setPathname()}>API</Link>
              </li>
              <li>
                <Link to="/" onClick={() => setPathname()}>Documentation</Link>
              </li>
              <li style={pathname === '/partner'? activeBtn: {}}>
                <Link to="/partner" onClick={() => setPathname()}>Partner</Link>
              </li>
            </ul>
            <div className="text-center py-[1rem]">
              <button className="border-none text-[#C7C7C7] text-[0.8rem] shrink-0">Sign Up</button>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 bg-[#2A2A2A] p-[1em] rounded-sm h-fit">
            <DehazeIcon className="text-[#636363]" onClick={toggleMobileMenu} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
