import React from 'react';
import animeImg from '../utils/anime-art.png';
import stockPhotosImg from '../utils/stock-photos.png';
import headshotImg from '../utils/headshot.png';
import aiAvatarsImg from '../utils/ai-avatars.png';
import wallpapersImg from '../utils/wallpapers.png';
import aiImg1 from '../utils/ai-art/img1.png';
import aiImg2 from '../utils/ai-art/img2.png';
import aiImg3 from '../utils/ai-art/img3.png';
import aiImg4 from '../utils/ai-art/img4.png';
import aiImg5 from '../utils/ai-art/img5.png';
import aiImg6 from '../utils/ai-art/img6.png';
import aiImg7 from '../utils/ai-art/img7.png';
import aiImg8 from '../utils/ai-art/img8.png';
import aiImg9 from '../utils/ai-art/img9.png';
import aiImg10 from '../utils/ai-art/img10.png';
import aiImg11 from '../utils/ai-art/img11.png';
import aiImg12 from '../utils/ai-art/img12.png';
import { Fade } from 'react-reveal';


function Section8() {
  return (
    <div>
        <div className="container md:m-auto px-4 py-[50px] lg:py-[140px] relative mt-20">
            <svg className="absolute top-0 left-0 z-[-1] max-w-[100%]" width="1920" height="1173" viewBox="0 0 1920 1173" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_746_55)">
                <path d="M-45 983C-30.0548 983 -16.3822 982.61 -1.69013 979.89C28.8578 974.236 46.705 960.414 79.7025 966.069C117.792 972.597 163.865 954.526 201.045 946.719C249.608 936.522 290.82 912.663 336.762 896.271C363.916 886.582 392.052 879.79 419.088 869.665C450.674 857.836 479.813 842.267 510.561 828.892C558.19 808.175 603.704 787.381 648.705 762.031C691.003 738.203 722.035 703.565 755.112 670.119C774.22 650.798 793.566 631.642 812.983 612.588C826.775 599.054 843.741 587.633 855.547 572.506C881.165 539.677 925.272 506.507 964.568 489.923C1058.99 450.075 1153.61 411.686 1248.88 373.478C1292.5 355.985 1338.39 345.501 1383.85 332.878C1480.42 306.066 1578.45 281.321 1676.01 257.897C1766.97 236.058 1860.34 227.698 1952.85 213.324C2008.08 204.743 2063.34 195.723 2119 190" stroke="#7242FB" stroke-width="17" stroke-linecap="round"/>
                </g>
                <defs>
                <filter id="filter0_f_746_55" x="-234.5" y="0.499512" width="2543" height="1172" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="90.5" result="effect1_foregroundBlur_746_55"/>
                </filter>
                </defs>
            </svg>

            <h3 className="text-[#CECECE] text-[20px] font-[600] text-center mb-[48px] lg:mb-[100px] 2xl:mb-[120px]">Image generation superpowers.<br />Ready to use today for infinite use cases.</h3>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[60px] xl:gap-[154px]">
                <Fade bottom>
                    <div className="flex flex-col w-full max-w-[428px] h-[387px] bg-[#121212] p-[8px] rounded-[6px] mx-auto">
                        <div className="h-[136px] 2xl:h-[176px] grid grid-cols-6 gap-[4px]">
                            <div className="h-[77px]"><img className="h-full" src={aiImg1} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg2} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg3} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg4} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg5} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg6} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg7} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg8} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg9} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg10} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg11} /></div>
                            <div className="h-[77px]"><img className="h-full" src={aiImg12} /></div>
                        </div>
                        <div className="px-[42px] pt-[28px] pb-[16px]">
                            <h3 className="text-white text-[20px] font-[600] mb-[12px]">AI Art</h3>
                            <p className="text-[#8C8C8C] text-[15px] font-[500]">
                                Effortlessly create unique artworks by bringing together your imagination and the power of generative AI.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full max-w-[428px] h-[387px] bg-[#121212] p-[8px] rounded-[6px] mx-auto">
                        <div className="h-[136px] 2xl:h-[176px]">
                            <img src={animeImg} />
                        </div>
                        <div className="px-[42px] pt-[28px] pb-[16px]">
                            <h3 className="text-white text-[20px] font-[600] mb-[12px]">Anime Art</h3>
                            <p className="text-[#8C8C8C] text-[15px] font-[500]">
                                Easily create exceptional anime art online with the joint power of AI and your imagination.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full max-w-[428px] h-[387px] bg-[#121212] p-[8px] rounded-[6px] mx-auto">
                        <div className="h-[136px] 2xl:h-[176px]">
                            <img src={stockPhotosImg} />
                        </div>
                        <div className="px-[42px] pt-[28px] pb-[16px]">
                            <h3 className="text-white text-[20px] font-[600] mb-[12px]">Stock photos</h3>
                            <p className="text-[#8C8C8C] text-[15px] font-[500]">
                                Generate your own license-free stock photos using our powerful photo-realistic AI models.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full max-w-[428px] h-[387px] bg-[#121212] p-[8px] rounded-[6px] mx-auto">
                        <div className="h-[136px] 2xl:h-[176px]">
                            <img src={headshotImg} />
                        </div>
                        <div className="px-[42px] pt-[28px] pb-[16px]">
                            <h3 className="text-white text-[20px] font-[600] mb-[12px]">Headshots</h3>
                            <p className="text-[#8C8C8C] text-[15px] font-[500]">
                                Make perfect headshots for your CV, Tinder, or other documents based on your own photographs.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full max-w-[428px] h-[387px] bg-[#121212] p-[8px] rounded-[6px] mx-auto">
                        <div className="h-[136px] 2xl:h-[176px]">
                            <img src={aiAvatarsImg} />
                        </div>
                        <div className="px-[42px] pt-[28px] pb-[16px]">
                            <h3 className="text-white text-[20px] font-[600] mb-[12px]">AI Avatars</h3>
                            <p className="text-[#8C8C8C] text-[15px] font-[500]">
                                Use our powerful AI tools to create a one-of-a-kind illustrated persona for yourself or your brand.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full max-w-[428px] h-[387px] bg-[#121212] p-[8px] rounded-[6px] mx-auto">
                        <div className="h-[136px] 2xl:h-[176px]">
                            <img src={wallpapersImg} />
                        </div>
                        <div className="px-[42px] pt-[28px] pb-[16px]">
                            <h3 className="text-white text-[20px] font-[600] mb-[12px]">Wallpapers</h3>
                            <p className="text-[#8C8C8C] text-[15px] font-[500]">
                                Use the joint power of your imagination and generative AI to easily make your own unique wallpapers.
                            </p>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    </div>
  )
}

export default Section8;