import React from 'react'
import PrimaryButton1 from './Components/PrimaryButton1';
import img from '../utils/control-net.png';
import img2 from '../utils/fake-owl.PNG';
import { Fade } from 'react-reveal';

function Section6() {
  return (
    <div className="container m-auto px-4 py-[50px] lg:py-[100px]">
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-[48px]">
            <Fade bottom>
                <div className="row-start-1 xl:row-start-auto md:col-span-3 flex flex-col lg:flex-row justify-center items-center relative xl:justify-start">
                    <img src={img} width={677} height={386} className="mb-[14px] md:mb-[16px] lg:mb-0" />
                    <svg className="lg:absolute w-[40vw] h-[25vw] ml-[103px] mt-[-57px] sm:ml-[160px] sm:mt-[-92px] md:ml-[245px] md:mt-[-125px] lg:ml-[336px] lg:mt-[336px] xl:m-0 xl:h-auto xl:w-auto xl:left-[323px] xl:top-[197px]" width="529" height="311" viewBox="0 0 529 311" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="226" width="303" height="306" rx="10" fill="#F1F1F1"/>
                        <path d="M334.373 111.151C333.957 109.487 331.884 110.765 331.313 109.055C329.787 104.475 324.78 99.7834 321.739 96.0806C318.621 92.2849 319.4 87.7661 316.526 84.1264C311.246 77.4382 315.62 56.0599 319.869 49.8503C340.127 20.243 377.858 21.4095 410.63 21.4095C421.385 21.4095 428.976 21.2717 437.371 27.8682C446.049 34.6865 448.589 48.2988 448.589 58.915C448.589 64.1084 448.589 69.3018 448.589 74.4951C448.589 79.7254 446.515 83.0418 445.586 88.1489C444.638 93.3642 439.561 97.5582 437.598 102.199C435.6 106.921 430.535 106.706 428.193 112.171" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M336.414 114.21C329.922 114.21 327.327 124.843 324.403 129.054C322.256 132.144 320.159 135.178 318.907 138.685C317.579 142.403 313.307 144.916 311.995 147.976C308.486 156.165 301.574 163.085 298.795 171.771C295.621 181.688 292.126 190.92 285.877 199.419C284.265 201.612 282.784 207.2 280.778 208.144C277.275 209.792 278.286 214.631 278.286 217.775C278.286 223.263 278.512 228.758 277.776 234.205C276.835 241.17 274.207 247.461 274.207 254.487C274.207 263.347 271.292 273.118 273.753 281.908C275.822 289.298 276.128 299.635 280.325 305.93" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M433.293 114.21C433.003 119.435 429.328 124.345 428.137 129.507C426.596 136.186 423.716 142.4 421.735 148.883C420.028 154.471 420.815 159.25 418.223 164.746C416.135 169.172 416.006 174.555 414.143 179.25C406.317 198.973 400.916 218.921 387.799 236.075C384.221 240.753 382.46 246.591 378.791 251.201C375.114 255.821 370.63 260.119 366.78 264.685C360.918 271.638 352.074 283.495 342.135 283.495C336.174 283.495 331.559 287.938 325.989 289.387C317.122 291.692 308.12 294.09 299.361 296.865C292.019 299.191 286.105 299.811 278.285 299.811" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M344.571 279.416C350.601 285.446 344.327 294.353 343.607 300.831C343.517 301.644 343.168 307.281 343.777 307.403C346.697 307.987 350.827 308.925 353.749 308.31C355.057 308.034 360.697 307.225 361.397 306.213C363.919 302.57 362.927 296.349 362.927 292.106C362.927 285.157 362.927 278.207 362.927 271.257" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M384.344 247.802C384.344 257.245 384.344 266.687 384.344 276.13C384.344 283.106 385.828 288.763 387.176 295.506C387.446 296.853 386.864 300.069 388.65 300.605C391.436 301.441 396.353 300.199 399.074 299.358C404.412 297.708 407.799 299.071 407.799 292.786C407.799 271.441 411.853 244.111 393.522 229.446" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M346.612 50.9834C346.612 57.308 341.722 64.5771 346.102 70.416C348.615 73.7676 356.904 75.4583 361.002 75.4583C366.092 75.4583 369.698 75.0226 374.542 73.532C382.34 71.1327 374.132 56.0027 369.5 53.4762C367.33 52.2923 363.538 53.023 361.115 53.023C355.601 53.023 350.086 53.023 344.572 53.023" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M417.996 48.9437C416.415 45.9797 398.472 55.9346 408.875 66.3367C413.229 70.691 422.692 74.1155 428.194 69.7927C431.62 67.101 431.444 55.4402 429.214 52.343C426.352 48.3684 413.196 48.794 408.818 50.9833" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M390.461 81.5772C396.172 82.0531 398.335 93.6543 400.149 98.2904C400.761 99.8531 403.631 110.589 402.585 109.961C400.228 108.547 400.571 104.346 399.866 101.973C398.71 98.0846 396.695 96.1579 396.58 91.7751C396.452 86.9327 393.546 79.9606 389.441 77.498" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M358.848 143.784C358.848 149.903 358.848 156.022 358.848 162.14C358.848 166.477 358.435 168.828 356.468 172.678C354.928 175.695 355.789 180.25 355.789 183.556C355.789 198.144 354.466 211.947 350.576 226.047C348.319 234.229 341.847 242.363 336.413 248.596C330.607 255.255 324.941 263.432 317.83 268.765C313.697 271.864 310.119 275.321 305.366 277.829C299.714 280.812 293.272 283.211 287.463 285.535" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M267.066 281.455C262.889 279.888 268.644 257.209 269.333 253.354C271.138 243.247 271.146 231.526 271.146 221.288" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M282.365 205.991C282.365 211.697 279.305 215.696 279.305 221.288C279.305 226.982 278.739 234.398 275.453 239.191C272.116 244.057 277.132 252.915 273.697 257.037C272.382 258.615 273.187 262.644 273.187 264.572C273.187 267.203 271.147 268.324 271.147 271.031C271.147 276.271 271.942 281.977 269.674 286.838C266.908 292.765 269.227 273.128 272.393 267.405C276.14 260.632 278.331 252.789 280.325 245.31C281.701 240.151 285.424 235.783 285.424 230.466" stroke="black" stroke-width="3" stroke-linecap="round"/>
                        <path d="M18.1558 228.682L19.2015 227.606L18.1558 228.682ZM0.529447 213.021C0.517867 212.193 1.17998 211.512 2.00833 211.5L15.507 211.311C16.3353 211.3 17.0163 211.962 17.0278 212.79C17.0394 213.619 16.3773 214.3 15.5489 214.311L3.55011 214.479L3.71786 226.478C3.72944 227.306 3.06731 227.987 2.23897 227.999C1.41062 228.01 0.729732 227.348 0.718152 226.52L0.529447 213.021ZM17.1101 229.757L0.983564 214.075L3.07503 211.925L19.2015 227.606L17.1101 229.757ZM229.815 253.278C161.884 295.035 74.276 285.347 17.1101 229.757L19.2015 227.606C75.3832 282.239 161.483 291.76 228.244 250.722L229.815 253.278Z" fill="white"/>
                    </svg>
                </div>
                <div className="md:col-span-2">
                    <span className="text-[#8000FF] text-[14px] font-[500] md:px-[38px] py-[6px]">AI Tool</span>
                    <h1 className="text-[#F1F1F1] text-2xl md:text-[48px] font-[600] md:leading-[72px] mb-[32px]">Control Net</h1>
                    <p className="text-[#C3C3C3] leading-[24px] mb-[48px]">
                        ControlNet lets you copy compositions or human poses from a reference image. Harness the power of precision. Take full command of your image generation settings, ensuring every pixel aligns with your vision, effortlessly.
                    </p>
                    <PrimaryButton1>Try it out</PrimaryButton1>
                </div>
            </Fade>
        </div>
    </div>
  )
}

export default Section6;